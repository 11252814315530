import { render, staticRenderFns } from "./involvement-report-modal.vue?vue&type=template&id=17b051ca&scoped=true"
import script from "./involvement-report-modal.vue?vue&type=script&lang=js"
export * from "./involvement-report-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b051ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src3295261676/src/web/components/loading-spinner.vue').default})
